import React from 'react';
import Head from 'next/head';
import config from '@/Framework/config';
import BookDemoButton from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoModalLegacy/BookDemoButton';
import InvestorLoginForm from '../InvestorLoginForm';
import bottomPattern from '@/dealroadshow/ui/assets/bottomPattern.svg';
import styles from './investorLogin.scss';

/**
 * @param {Object} props
 */
function InvestorLogin(props) {
  return (
    <div className={ styles.pageWrapper }>
      <Head>
        <title>Experience a Better Roadshow | Deal Roadshow</title>
        <meta
          name="description"
          content="Secure, SEC compliant electronic roadshow solution that offers better tools for better execution of
          debt, equity, IPO and municipal roadshows."
        />
      </Head>
      <div className={ styles.intro }>
        <InvestorLoginForm { ...props } />
        <h2 className={ styles.introTitle }>
          Experience a better roadshow
        </h2>
        <p className={ styles.introDescription }>
          <strong>Deal Roadshow</strong> offers an exceptional investor experience and unparalleled insights into the
          syndication process, backed by fanatical 24x7 support.
        </p>
        <div className={ styles.bookDemoLinkWrp }>
          <BookDemoButton
            tenant={ config.tenant.dealroadshow.code }
            buttonComponent="a"
            className={ styles.bookDemoLink }
          />
        </div>
      </div>
      <div className={ styles.bottomPattern }>
        <img src={ bottomPattern } />
      </div>
    </div>
  );
}

export default InvestorLogin;

import React from 'react';
import InvestorLogin from '@/dealroadshow/ui/components/Landing/Institutional/InvestorLogin';
import LandingLayout from '@/dealroadshow/ui/components/Landing/Layout/LandingLayout';
import withGlobalProps from '@/Framework/Router/Next/withGlobalProps';

export const getServerSideProps = withGlobalProps(() => ({ props: {} }));

export default (): JSX.Element => (
  <LandingLayout>
    <InvestorLogin />
  </LandingLayout>
);
